// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const currentUrl: string = window.location.href;
// Firebase configurations
const firebaseConfig =
  process.env.NODE_ENV === 'production' && !currentUrl.includes('test')
    ? {
        apiKey: 'AIzaSyDAbfs7lGCUQVp0C1wDa_z2QXwTLokCu_U',
        authDomain: 'medical-tablet.firebaseapp.com',
        projectId: 'medical-tablet',
        storageBucket: 'medical-tablet.firebasestorage.app',
        messagingSenderId: '19633397535',
        appId: '1:19633397535:web:9b5f0d0632a60b10db1a2a',
      }
    : {
        apiKey: 'AIzaSyAETcCqJXGqSpoEUNy6WG5WERNXK5TSFeg',
        authDomain: 'medical-tablet-test.firebaseapp.com',
        projectId: 'medical-tablet-test',
        storageBucket: 'medical-tablet-test.firebasestorage.app',
        messagingSenderId: '158077923247',
        appId: '1:158077923247:web:c095676c8aa4c43ef4095a',
      };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const storage = getStorage(app);
